import "./App.css";
import Marquee from "react-fast-marquee";
import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import Typewriter from "typewriter-effect";
import cover from "./images/cover.jpg";
import streetcultback from "./images/street-culture-back.jpg";
import streetcultvideo from "./images/street_culture_video.mp4";
import verbumproject from "./images/verbum-project-image.png";
import verbumhistory from "./images/verbum-history-img.jpg";
import verbumcard from "./images/verbum-test-card.jpg";
import aneteportvideo from "./images/anete_portfolio_video.mp4";
import aneteprojects from "./images/aneteprojects.jpg";
import anetelanding from "./images/anetelanding.jpg";
import portfoliovido from "./images/portfolio_video.mp4";
import portfolioview from "./images/portfolio-project-image.jpg";
import portfolioskills from "./images/portfolio_skills.jpg";
import arrow from "./images/arrow_svg.svg";
import arrowdiag from "./images/arrow_diag_svg.svg";
import { useNavigate } from 'react-router-dom';
import Navbar from "./components/navbar";


const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const text = "REINIS";

const mediaFiles = [
  cover,
  streetcultback,
  streetcultvideo,
  verbumproject,
  verbumhistory,
  verbumcard,
  aneteportvideo,
  aneteprojects,
  anetelanding,
  portfoliovido,
  portfolioview,
  portfolioskills,
  arrow,
  arrowdiag,
];

function Home() {
  const [displayedText, setDisplayedText] = useState(text);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const hoverAreaRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  const handleRowClick = (id) => {
    // when done with the pages
    // navigate(`/projects/${id}`);
  };

  useEffect(() => {
    let loadedFiles = 0;

    const handleLoad = () => {
      loadedFiles += 1;
      setProgress((loadedFiles / mediaFiles.length) * 100);
      if (loadedFiles === mediaFiles.length) {
        setIsLoading(false);
      }
    };

    mediaFiles.forEach((src) => {
      const mediaElement = new Image();
      mediaElement.src = src;
      mediaElement.onload = handleLoad;
      mediaElement.onerror = handleLoad;
    });

  }, []);

  const getFilesForRow = useCallback((row) => {
    switch (row) {
      case 1:
        return [cover, streetcultback, streetcultvideo];
      case 2:
        return [anetelanding, aneteprojects, aneteportvideo];
      case 3:
        return [portfolioview, portfolioskills, portfoliovido];
      case 4:
        return [verbumcard, verbumhistory, verbumproject];
      default:
        return [];
    }
  }, []);

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (hoveredRow && hoverAreaRef.current) {
        const rect = hoverAreaRef.current.getBoundingClientRect();
        setMousePosition({
          x: event.clientX - rect.left,
          y: event.clientY - rect.top,
        });
      }
    };
  
    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, [hoveredRow]);
  

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      let iteration = 0;
      const intervalId = setInterval(() => {
        setDisplayedText((prev) =>
          prev
            .split("")
            .map((letter, index) =>
              index < iteration
                ? text[index]
                : letters[Math.floor(Math.random() * 26)]
            )
            .join("")
        );
        if (iteration >= text.length) clearInterval(intervalId);
        iteration += 1 / 15;
      }, 20);
      return () => clearInterval(intervalId);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  const hoverContent = useMemo(() => {
    if (hoveredRow) {
      const [image, rightImage, middleImage] = getFilesForRow(hoveredRow);
      return (
        <>
          <div
            className={`hover-image ${hoveredRow ? "visible" : ""}`}
            style={{
              transform: `translate(-90%, -60%) translate(${
                mousePosition.x * 0.05
              }px, ${mousePosition.y * 0.05}px)`,
            }}
          >
            <img src={image} alt="cover" />
          </div>
          <div
            className={`hover-image-middle ${hoveredRow ? "visible" : ""}`}
            style={{
              transform: `translate(-30%, -50%) translate(${
                mousePosition.x * 0.05
              }px, ${mousePosition.y * 0.05}px)`,
            }}
          >
            {hoveredRow <= 3 ? (
              <video
                src={middleImage}
                autoPlay
                muted
                loop
                preload="auto"
                playsInline
                style={{ visibility: hoveredRow ? "visible" : "hidden" }}
              />
            ) : (
              <img src={middleImage} alt="cover" />
            )}
          </div>
          <div
            className={`hover-image-right ${hoveredRow ? "visible" : ""}`}
            style={{
              transform: `translate(40%, -42%) translate(${
                mousePosition.x * 0.05
              }px, ${mousePosition.y * 0.05}px)`,
            }}
          >
            <img src={rightImage} alt="cover" />
          </div>
        </>
      );
    }
    return null;
  }, [hoveredRow, mousePosition, getFilesForRow]);

  if (isLoading) {
    return (
      <div className="loading-screen">
        <div className="progress-bar">
          <div className="progress" style={{ width: `${progress}%` }}></div>
        </div>
        <p>Loading... {Math.floor(progress)}%</p>
        <h3>...</h3>
      </div>
    );
  }

  return (
    <>
      <div className="landing-page">
        <Navbar/>
        <section id="landing">
          <div className="landing-name">
            <h1>{displayedText}</h1>
            <h2>MUIZNIEKS</h2>
          </div>
          <div className="landing-bio">
            <div className="corners">
              <div className="corner-shape top-left"></div>
              <div className="corner-shape top-right"></div>
            </div>
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .changeDelay(30)
                  .typeString(
                    "Student, currently studying graphic design and web development."
                  )
                  .pauseFor(1500)
                  .typeString(
                    " Also specialize in Full-Stack project development, Microsoft Power-Apps and UI Design."
                  )
                  .start();
              }}
            />
            <div className="corners">
              <div className="corner-shape bottom-left"></div>
              <div className="corner-shape bottom-right"></div>
            </div>
          </div>
        </section>
      </div>
      <Marquee>
        <div className="marquee">
          <div className="projects-heading">PROJECTS</div>
          <div className="projects-heading">PROJECTS</div>
          <div className="projects-heading">PROJECTS</div>
        </div>
      </Marquee>
      <section id="projects">
        <div className="projects">
          <table>
            <tbody>
              {[
                {
                  id: 1,
                  title: "Street Culture",
                  description: "Print Magazine",
                  year: 2024,
                },
                {
                  id: 2,
                  title: "Anete",
                  description: "Client Portfolio",
                  year: 2024,
                },
                {
                  id: 3,
                  title: "Portfolio",
                  description: "Old Personal Portfolio",
                  year: 2024,
                },
                {
                  id: 4,
                  title: "Verbum",
                  description: "Language Learning App",
                  year: 2023,
                },
              ].map(({ id, title, description, year }) => (
                <tr
                  key={id}
                  onMouseEnter={() => setHoveredRow(id)}
                  onMouseLeave={() => setHoveredRow(null)}
                  onClick={() => handleRowClick(id)}
                >
                  <td>{id}</td>
                  <td>{title}</td>
                  <td className="project-desc">{description}</td>
                  <td>{year}</td>
                  <td>{">>>"}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div ref={hoverAreaRef} className="table-row">
            {hoverContent}
          </div>
        </div>
      </section>
      <section id="about">
        <div className="about-section">
          <div className="about-left">
            <div className="about-heading">
              <h3>Bootcamps</h3>
              <img src={arrow} alt="Arrow Icon" />
            </div>
            <table>
              <tbody>
                {[
                  {
                    year: 2024,
                    institution: "Science Park",
                    program: "Startup Prog. SustainTech",
                  },
                  {
                    year: 2022,
                    institution: "Accenture",
                    program: "Microsoft Tech and .NET",
                  },
                  {
                    year: 2021,
                    institution: "Datorium",
                    program: "Python Advanced",
                  },
                  { year: 2021, institution: "Datorium", program: "Django" },
                  {
                    year: 2020,
                    institution: "Datorium",
                    program: "Python Boost",
                  },
                  { year: 2020, institution: "Datorium", program: "Python" },
                  { year: 2019, institution: "Datorium", program: "VB.NET C#" },
                  {
                    year: 2020,
                    institution: "All Oportunities",
                    program: "Digital Skills",
                  },
                ].map(({ year, institution, program }) => (
                  <tr key={year + program}>
                    <td>{year}</td>
                    <td>{institution}</td>
                    <td>{program}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="socials-section">
              <div className="about-heading">
                <h3>Get In Touch</h3>
                <img src={arrow} alt="Arrow Icon" />
              </div>
              <div className="socials">
                <img src={arrowdiag} alt="Arrow Icon" />
                <a href="mailto:muizreinis@gmail.com">Mail</a>
              </div>
              <div className="socials">
                <img src={arrowdiag} alt="Arrow Icon" />
                <a href="https://www.linkedin.com/in/reinis-muiznieks-7b9021299/" target="_blank">LinkedIn</a>
              </div>
            </div>
          </div>
          <div className="about-right">
            <div className="about-heading">
              <h3>About</h3>
              <img src={arrow} alt="Arrow Icon" />
            </div>
            <p>
              I am a student studying in the field of Web Development and Graphic Design. 
              During high school, I attended a technical school and graduated with a qualification as a Programming Technician (professional secondary education).
              Aside from my school work I enjoy participating in many different events and boot camps that help me expand my knowledge. <br/><br/>
              In my free time I love to listen to music and watch movies from which I also draw a lot of inspiration from.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;

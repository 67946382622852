import React from 'react';
import Navbar from '../components/navbar';

const StreetCulture = () => {
  return (
    <>
    <Navbar/>
    <section>    <div>
      <h1>Street Culture</h1>
      <p>This is the detailed page for the Street Culture project.</p>
    </div>
    </section>
    </>
  );
};

export default StreetCulture;
import React from 'react';
// import SmoothScrollLink from './SmoothScrollLink';
import SmoothScrollLink from './smoothscroll';

const Navbar = () => {
  return (
    <nav>
      <div className="navbar">
        <a href="/"><div className="nav-logo">RM</div></a>
        <ul className="nav-links">
          <li>
            <SmoothScrollLink to="#about">ABOUT</SmoothScrollLink>
          </li>
          <li>
            <SmoothScrollLink to="#projects">PROJECTS</SmoothScrollLink>
          </li>
          <li>
            <SmoothScrollLink to="#about">CONTACT</SmoothScrollLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
